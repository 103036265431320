<template>
   <div 
      class="b-chat-message" 
      :class="{ 'is-from-customer' : message.isFromCustomer }"
   >
      <img 
         v-if="!message.isFromCustomer" 
         class="b-chat-message__image" 
         :src="dbResponsibleImage" 
      />

      <div class="b-chat-message__wrap">
         <div class="b-chat-message__info">
            {{ message.time }} - {{ senderName }}
         </div>

         <div class="b-chat-message__text">
            {{ message.text }}
         </div>
      </div>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   export default {
      props: {
         message: {}
      },
      computed: {
         ...mapState([
            'responsible',
            'isDB'
         ]),
         customerMsgAuthorLabel() {
            return this.isDB
               ? this.$t('customer')
               : this.$t('chat_you');
         },
         senderName() {
            if( !this.responsible) {
               return '';
            }
            return this.message.isFromCustomer
               ? this.customerMsgAuthorLabel
               : this.responsible.name;
         },
         dbResponsibleImage() {
            // ToDO: think about adding an image to the profile of DB users, or remove the image
            return 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500';
         },
      },
   };
</script>

<style lang="less">
   .b-chat-message {
      --text-side-margin: 100px;
      margin-top: var(--message-margin);
      .h-flex-row-left-top;

      &__image {
         width: 50px;
         height: $width;
         object-fit: cover;
         border-radius: 100%;
         margin-right: 15px;
         margin-top: $margin-right;
         .flex-shrink(0);
      }

      &__wrap {
         margin-right: var(--text-side-margin);
      }

      &__info {
         color: var(--color-gray-3);
         font-size: calc((12 / var(--base-value)) * 1rem);
         line-height: 1;
      }

      &__text {
         margin-top: 3px;
         padding: 15px 20px;
         font-size: calc((15 / var(--base-value)) * 1rem);
         background-color: var(--color-gray-light);
         border-radius: 10px;
         .h-font-regular;
      }

      &.is-from-customer {
         text-align: right;
         .justify-content(flex-end);

         .b-chat-message__wrap {
            margin-left: var(--text-side-margin);
            margin-right: 0;
         }

         .b-chat-message__text {
            text-align: left;
            background-color: var(--color-gray);
            color: white;
         }
      }
   }
</style>
