<template>
   <transition name="slide-fade">
      <div>
         <div class="p-communication__header">
            <!-- title -->
            <div class="h-title">
               {{ this.$t("trip_communication") }}
            </div>

            <Responsible />
         </div>

         <div class="l-flex-grid h-block-margin-top">
            <!-- history -->
            <div class="l-flex-grid__item">
               <div class="h-section-title">
                  {{ this.$t('trip_history') }}
               </div>

               <div class="h-white-block flex-grow-1">
                  <Feed />
               </div>
            </div> 

            <!-- chat -->
            <div class="l-flex-grid__item">
               <div class="h-section-title">
                  {{ this.$t('trip_messages') }}
               </div>

               <div class="p-communication__chat-wrap">
                  <Chat />
               </div>
            </div>
         </div>
      </div>
   </transition>
</template>

<script>
   import Chat from '@/components/communication/Chat.vue';
   import Feed from '@/components/communication/Feed.vue';
   import Responsible from '@/components/communication/Responsible.vue';
   import stopLoadingOnMounted from '@/mixins/stopLoadingOnMounted.js';

   import { mapActions,mapGetters,mapState } from 'vuex';

   export default {
      components: {
         Responsible,
         Chat,
         Feed,
      },
      mixins: [
         stopLoadingOnMounted,
      ],
      computed: {
      ...mapGetters([
         'unreadMessages'
      ]),
      ...mapState([
         'isDb'
      ])
      },
      methods: {
         ...mapActions([
            'clearUnreadMessages'
      ]),
      },
      mounted() {
         if (!this.isDb) {
            this.clearUnreadMessages(this.unreadMessages);
         }
      },
   };
</script>

<style lang="less">
   .p-communication {
      &__header {
         .h-flex-row-left-top;

         .h-title {
            .flex-grow(1);
         }
      }

      &__chat-wrap {
         .h-white-block;
         padding: 0;
         .flex-grow(1);
         .h-flex-col;
      }

      @media only screen and (max-width : @breakpoint-mobile) {
         &__header {
            .h-flex-col;
            .align-items(flex-start);

            .h-title {
               margin-bottom: 30px;
            }
         }

         .l-flex-grid {
            .flex-direction(column-reverse);
         }
      }
   }
</style>
