<template>
   <div class="b-write-message">
      <Textarea
         v-model="message"
         :autoResize="true"
         :placeholder="$t('enter_your_message')"
         @keydown.enter='sendMessage()'
      />

      <Button
         class="is-small"
         :label="$t('send')"
         :disabled="!messageHasContent"
         @click="sendMessage"
      />
   </div>
</template>

<script>
   import { mapActions } from 'vuex';
   import Button from 'primevue/button';
   import Textarea from 'primevue/textarea';

   export default {
      components: {
         Textarea, Button
      },
      data() {
         return {
            message: '',
         }
      },
      computed: {
         messageHasContent() {
            return this.message.replace(/\s/g, '').length > 0
         }
      },
      methods: {
         ...mapActions([
            'sendChatMessage'
         ]),
         sendMessage() {
            if(!this.messageHasContent) {
               return;
            }

            this.sendChatMessage(this.message).then(() => this.$emit('newMessageAdded'));

            this.message = '';
         },
      },
   };
</script>

<style lang="less">
   .b-write-message {
      background-color: var(--color-gray-light);
      padding: 15px 25px;
      .h-flex-row-left-center;
      .align-items(flex-end);

      .p-inputtextarea {
         margin-right: 15px;
      }
   }
</style>