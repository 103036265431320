<template>
   <div class="b-feed-item">
      <!-- icon + line -->
      <div class="b-feed-item__left">
         <div :class="`b-feed-item__icon ${item.icon}`"></div>
         <div class="b-feed-item__line"></div>
      </div>

      <!-- content -->
      <div class="b-feed-item__content">
         <div class="b-feed-item__time"> {{ item.date }} </div>
         <div class="b-feed-item__text" v-html="item.text"></div>
      </div>
   </div>
</template>

<script>
   export default {
      props: {
         item: {}
      },
   };
</script>

<style lang="less">
   .b-feed-item {
      --icon-width: 40px;

      .h-flex-row-left-top;

      &__left {
         .h-flex-col-center;
         .align-items(center);
         .flex-shrink(0);
      }

      &__icon {
         background-color: var(--color-gray-light);
         border: solid 1px var(--color-gray-4);
         width: var(--icon-width);
         height: var(--icon-width);
         border-radius: 100%;
         color: var(--color-gray-2);
         .h-flex-row-center;
         .align-items(center);

         &:before {
            font-size: calc(~"var(--icon-width) / 2");
            line-height: 1;
         }
      }

      &__line {
         background-color: var(--color-gray-4);
         width: 2px;
         height: 30px;
      }

      &__content {
         margin-top: 6px;
         margin-left: 10px;
      }

      &__time {
         color: var(--color-gray-3);
         font-size: 12px;
         line-height: 1;
      }

      &__text {
         margin-top: 3px;
         font-size: 15px;
         line-height: 1;
         .h-font-regular;

         a {
            color: var(--color-red);
         }
      }
   }
</style>