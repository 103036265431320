<template>
   <div class="b-responsible">
      <img class="b-responsible__image" :src="responsibleImage" v-if="hasResponsible"/>

      <div class="b-responsible__content">
         <div class="b-responsible__clarification">
            {{ this.$t('db_trip_responsible') }}
         </div>

         <div class="b-responsible__name"> {{ responsibleName }} </div>

         <div class="b-responsible__phone"> {{ responsiblePhone}} </div>
      </div>
   </div>
</template>

<script>
   import { mapState } from 'vuex';

   export default {
      computed:{
         ...mapState([
            'responsible'
         ]),
         hasResponsible() {
            return this.responsible ? true : false;
         },
         responsibleName() {
            return this.responsible ? this.responsible.name : this.$t('awaiting_trip_advisor');
         },
         responsibleImage() {
            //ToDO: @gprego what to do with this
            return 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500';
         },
         responsiblePhone() {
            return this.responsible ? this.responsible.phone : '';
         }
      }
   };
</script>

<style lang="less">
   .b-responsible {
      .h-flex-row-left-center;

      &__image {
         border-radius: 100%;
         width: 70px;
         height: 70px;
         margin-right: 10px;
         object-fit: cover;
      }

      &__clarification,
      &__phone {
         color: var(--color-gray-2);
         line-height: 1;
      }

      &__name {
         .h2-style;
      }
   }
</style>