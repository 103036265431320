<template>
    <div class="b-chat">
        <div ref="messagesWraper" class="b-chat__messages-wrap">
            <!-- initial message -->
            <ChatMessage :message="initialMessage" />

            <!-- messages -->
            <ChatMessage
                v-for="message in chatMessages"
                :message="message"
                :key="message.id"
            />
        </div>

        <WriteMessage @newMessageAdded="scrollToLastMessage()"/>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import dateManager from '@/helpers/date.js';
    import ChatMessage from './ChatMessage.vue';
    import WriteMessage from './WriteMessage.vue';

    export default {
        components: {
            ChatMessage,
            WriteMessage,
        },
        data() {
            return {
                initialMessage: {
                    text: 'Hi, how can I help you?' ,
                    time: dateManager.fromNow(this.rdsTimeStamp),
                }
            };
        },
        computed: {
            ...mapState({
                rdsTimeStamp: state => state.communication.rdsTimeStamp,
                chatMessages: state => state.communication.chatMessages,
            }),
        },
        methods: {
            scrollToLastMessage() {
                let messagesWraper = this.$refs.messagesWraper;
                messagesWraper.scrollTop = messagesWraper.scrollHeight;
            },
        },
        mounted() {
            setTimeout(this.scrollToLastMessage, 1000);
        }
    };
</script>

<style lang="less">
    .b-chat {
        --message-margin: 30px;
        padding-top: 25px;
        .flex-grow(1);
        .h-flex-col;

        &__messages-wrap {
            max-height: 400px;
            overflow-y: auto;
            scroll-behavior: smooth;
            .flex-grow(1);
        }

        .b-chat-message {
            padding-left: 25px;
            padding-right: 25px;

            &:first-of-type {
                margin-top: 0;
            }

            &:not(.is-from-customer) + .b-chat-message.is-from-customer { // decrease top margin when customer answers to db responsible
                margin-top: calc(~"var(--message-margin) / 2");
            }
        }

        .b-write-message {
            margin-top: 40px;
            .flex-shrink(0);
        }
    }
</style>