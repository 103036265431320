<template>
    <div class="b-feed">
        <feed-item v-for="(item,index) in feedEntries" :item="item" :key="index" />
    </div>
</template>

<script>
    import FeedItem from '@/components/communication/FeedItem.vue';
    import {mapActions,mapState} from 'vuex';

    export default {
        components: {
            FeedItem,
        },
        computed: {
            ...mapState({
               feedEntries: state => state.communication.feedEntries,
            }),
        },
        methods:{
            ...mapActions([
                'fetchCommunicationData'
            ])
        },
    };
</script>


<style lang="less">
    .b-feed {
        max-height: 490px;
        overflow: auto;
        scroll-behavior: smooth;

        .b-feed-item:first-of-type { // mark first item
            .b-feed-item__icon {
                background-color: var(--color-gray);
                color: white;
            }
        }

        .b-feed-item:last-child .b-feed-item__line { // hide line of last item
            display: none;
        }
    }
</style>